<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none py-0 px-0 d-lg-block"
        >
          <v-img
            max-width="100%"
            max-height="100vh"
            style="position:absolute "
            :src="require(`@/assets/images/banner.png`)"
          ></v-img>

        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg  pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="8"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>

                <v-card-text>
                  <v-img
                  width="70%"
                  class="mx-auto my-6 "
                  :src="require(`@/assets/logos/PNG/Recurso 7.png`)"
                  ></v-img>
                </v-card-text>

                <v-card-text>
                  <p
                    class="text-2xl text-center font-weight-semibold custom mb-10"
                  >
                  Bienvenido a Iluminar
                  </p>
                  <p class="text-l mb-2 text-center">
                    Ingresa con tu cuenta
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form ref="loginForm" @submit.prevent="loginUser">
                    <v-text-field
                      v-model="form.username"
                      outlined
                      label="Email o usuario"
                      placeholder="Email o usuario"
                      hide-details="auto"
                      class="mb-6"
                      background-color="rgb(246, 243, 222)"
                      :rules="[rules.required]"
                    ></v-text-field>

                    <v-text-field
                      v-model="form.password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Contraseña"
                      placeholder="Contraseña"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      hide-details="auto"
                      class="mb-2"
                      background-color="rgb(246, 243, 222)"
                      :rules="[rules.passRequired]"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <div class="d-flex align-center justify-space-between flex-wrap">

                        <a
                        @click="() => showForgotModal = !showForgotModal"
                        >Olvidaste tu contraseña?</a>
                    </div>

                    <v-col align="left" v-if="loginError">
                      <span class="caption" style="color:red;">
                        {{ loginError }}
                      </span>
                    </v-col>

                    <v-btn
                      block
                      color="#750D7C"
                      type="submit"
                      class="mt-6 custom-btn"
                      :disabled="loading"
                      :loading="loading"
                    >
                      Ingresar
                    </v-btn>

                    <div class="mt-10 d-flex justify-center">
                      <span >
                        <span class="">
                        Powered by
                          <a
                          href="https://letscode.ar"
                          class="custom-link"
                          >
                          Let's Code
                          </a>
                        </span>
                      </span>
                    </div>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>


    <v-dialog
      v-model="showForgotModal"
      width="auto"
      persistent
    >
      <v-card :loading="forgotModalLoad">
        <v-form ref="recoveryForm" @submit.prevent="recovery()">
          <v-card-title class="text-h5 grey lighten-2">¿Olvidaste tu contraseña?</v-card-title>
          <v-card-subtitle class="pt-3">Ingresa tu Email para recibir el instructivo con los pasos a seguir</v-card-subtitle>
          <v-card-text>
            <v-col cols="10" md="10" >
                <v-text-field
                  ref="name"
                  v-model="recoveryEmail"
                  outlined
                  dense
                  autocomplete="off"
                  hide-details="auto"
                  label="Email de recuperación"
                  validate-on-blur
                  :rules="[rules.required, rules.email]"
                />
            </v-col>
          </v-card-text>
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
            color="error"
            outlined
            @click="close()"
            :disabled="forgotModalLoad"
            >
              CANCELAR
            </v-btn>
            <v-btn
            color="success"
            @click="recovery()"
            :disabled="forgotModalLoad"
            >
              Enviar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <AlertPopUp ref="alert_pop_up"/>

  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import axios from 'axios'
import AlertPopUp from '@/components/AlertPopUp.vue'

export default {
    data: () => {
        return {
            form: {
                username: "",
                password: "",
            },
            loading: false,
            remember_me: false,
            recoveryEmail: "",
            showForgotModal: false,
            forgotModalLoad: false,
            rules: {
                required: value => !!value || "Este campo es obligatorio",
                passRequired: value => !!value || "El password es obligatorio",
                minLength: value => value && value.length >= 8 || "Ingrese un mínimo de 8 carácteres",
                email: value => {
                    const pattern = /^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Ingrese un mail valido";
                },
            },
            loginError: null,
            loging: false
        };
    },
    methods: {
        async loginUser() {
            if (this.$refs.loginForm.validate()) {
                try {
                    this.loading = true
                    const response = await this.$api.login(this.form);
                    // console.log(response);
                    const form = {
                        access: response.access,
                        refresh: response.refresh,
                        remember: this.remember_me,
                    };
                    const userData = await axios.get("api/get-user-info", {
                        baseURL: process.env.VUE_APP_API_URL,
                        headers: {
                            "Authorization": `Bearer ${response.access}`,
                        }
                    });
                    // this.loging = false; //enable button
                    form.user = userData.data;
                    // console.warn(form);
                    this.$store.dispatch("session/setSessionData", form);

                    this.$router.push("/home");
                }
                catch (error) {
                    if (error.response.status === 400) {
                        this.loginError = error.response.data;
                    }
                    // this.loging = false; //enable button
                    if (error?.response?.status === 401) {
                        this.loginError = error.response.data.detail;
                    }
                    //  else {
                    //   console.log(error);
                    //   this.loginError = "Ocurrió un problema, vuelva a intentar más tarde"
                    // }
                } finally { this.loading = false}
            }
        },
        async recovery() {
          if (this.$refs.recoveryForm.validate()) {
              try {
                this.forgotModalLoad = true
                const email = {
                  email: this.recoveryEmail
                };
                const response = await this.$api.resetPassword(email);

                if (response.status === 200) {
                  this.close();
                  this.$refs.alert_pop_up.displayAlert({ message: 'Email de recuperación enviado, sigue desde tu email', type: 'success' })
                }

              } catch (error) {
                if (error.response.status === 400){
                  this.$refs.alert_pop_up.displayAlert({ message: 'No existe una cuenta asociada a ese email', type: 'error' })
                }
                if (error.response.status !== 400) {
                  this.$refs.alert_pop_up.displayAlert({ message: 'No se pudo enviar el email de recuperación', type: 'error' })
                }
              } finally { this.forgotModalLoad = false}
          }
        },
        close() {
            this.resetForm();
            this.showForgotModal = false;
        },
        resetForm() {
            this.$refs.recoveryForm.reset();
        },
    },
    mounted() {
    },
    setup() {
        const isPasswordVisible = ref(false);
        const email = ref("");
        const password = ref("");
        const socialLink = [
            {
                icon: mdiFacebook,
                color: "#4267b2",
                colorInDark: "#4267b2",
            },
            {
                icon: mdiTwitter,
                color: "#1da1f2",
                colorInDark: "#1da1f2",
            },
            {
                icon: mdiGithub,
                color: "#272727",
                colorInDark: "#fff",
            },
            {
                icon: mdiGoogle,
                color: "#db4437",
                colorInDark: "#db4437",
            },
        ];
        return {
            isPasswordVisible,
            email,
            password,
            socialLink,
            // Icons
            icons: {
                mdiEyeOutline,
                mdiEyeOffOutline,
            },
            // themeConfig
            appName: themeConfig.app.name,
            appLogo: themeConfig.app.logo,
        };
    },
    components: { AlertPopUp }
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
.custom{
  color: #750D7C;
}
.custom-btn{
  color: white;
}

.custom-link {
  color: rgba(94, 86, 105, 0.68);
  text-decoration: none;
}

.custom-link:hover {
  color: rgb(42, 45, 249);
}
</style>
